@import "variables.scss";

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
    min-height: 100vh;
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 0 5% 0 5%;
    max-width: 90%;
}


.bold {
    font-weight: bold;
}

.normal {
    font-weight: normal;
}

.red {
    color: $theme_red;
}

.green {
    color: $theme_emerald_light;
}

.white {
    color: white;
}