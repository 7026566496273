@import "variables.scss";
@import "Main.scss";

.App {
    display: flex;
    flex-direction: column;

    color: $theme_emerald_light;
    
    background-color: #242731;
    overflow-x: clip;
}

html {
    scroll-behavior: smooth;
}

body {
    color: $theme_gunmetal;

    margin: 0;

    font-family: "Lato", "Open Sans", -apple-system, sans-serif;
    font-size: 1.2rem;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

a {
    text-decoration: none;
    color: inherit;
}

h1 {
    font-size: 2.25rem;
    margin: 0.5rem 0rem 0.5rem 0rem;
    
}

h2 {
    font-size: 1.5rem;
    margin: 0.5rem 0rem 0.5rem 0rem;
    
}

h3 {
    font-size: 1.2rem;
    margin: 0.5rem 0rem 0.5rem 0rem; 
}

h4 {
    font-size: 1.2rem;
    margin: 0.5rem 0rem 0.5rem 0rem;
}

@media (min-width: 768px) {
    h1 {
        font-size: 3.5rem;
        margin: 0.5rem 0rem 0.5rem 0rem;
    }

    h2 {
        font-size: 2rem;
        margin: 0.5rem 0rem 0.5rem 0rem;
        
    }
    
    h3 {
        font-size: 1.5rem;
        margin: 0.5rem 0rem 0.5rem 0rem;
    }
      
    h4 {
        font-size: 1.2rem;
        margin: 0.5rem 0rem 0.5rem 0rem;
    }
}
