
$theme_emerald: #10D183;
$theme_emerald_light: #65c892;
$theme_gunmetal: #333745;

$theme_ultra_red: #ff6978;
$theme_red: #ce4855;
$theme_medium_sea_green: #3fb975;   
$theme_light_green: #e6f6ed;

