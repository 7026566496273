.menu-items {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    // margin: 0 0 5rem 0;
}

.item-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;

    max-height: 450px;
    max-width: 300px;
    padding: 1rem;
    
    img {
        width: 100%;
        max-height: 250px;

        object-fit: cover;
        padding:1rem;
    }

    p {
        font-size: 1rem;
        margin: 0;
    }
}