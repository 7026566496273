@import "variables.scss";

.menu-section {
    width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;

    position: relative;
    overflow: hidden;

    border-radius: 0.5rem;
    box-shadow: 0 0.2rem 0.3rem 0.3rem rgba(0, 0, 0, 0.5);

    margin: 1rem;

    p {
        margin: 0.2rem;
        // font-size: 1rem;
    }

    h3, h4 {
        margin: 0.2rem;
        font-size: 1.2rem;
    }

}

.menu-day {
    position: absolute;
    top: 0.1rem;
    left: 0.1rem;

    background-color: $theme_gunmetal;
    padding: 0.5rem;
    font-size: 1.2rem;
    border-radius:  5px;
}

.menu-img {
    // border-radius: 0.5rem;
    height: 100%;
    position: absolutes;

    img {
        // border-radius: 0.5rem 0.5rem 0 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.menu-info {
    padding: 0.5rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: transparentize($theme_gunmetal, 0.3);
    backdrop-filter: blur(5px);
}

.menu-weekly-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

@media (min-width: 768px) {
    .menu-weekly-container {
        max-width: 60%;
    }
}