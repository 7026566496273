@import "variables.scss";

.footer {
    color: $theme_gunmetal;

    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-top: 3rem;
    padding: 2rem;
}

.footer-contact {
    display: flex;
    flex-direction: column;
    align-items: right;
    row-gap: 0.3rem;
}

/* Desktop*/
@media (min-width: 768px) {
    .footer {
        padding-right: 20%;
        padding-left: 20%;
    }
}
