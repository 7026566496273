@import "variables.scss";

.mail-form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 80px;
}

.mail-form {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 2rem;
    padding: 0.5rem;
    border-radius: 0.5rem;

    background-color: white;

    .email {
        font-size: 1rem;
        font-weight: 550;
        text-align: center;

        height: 100%;
        padding-left: 2rem;

        border: none;
        color: $theme_gunmetal;
        background-color: transparent;
    }

    .email::placeholder{
        color: transparentize($theme_gunmetal, 0.5);
    }
    
    .email:focus {
        outline: none;
        color: $theme_gunmetal;
    }

    .email:focus::placeholder {
        color: transparent;
    }

    .submit {
        height: 100%;
        width: 2rem;
        border: none;
        background: transparent;

        div {
            border-color: $theme_ultra_red;
        }
    }
}

.mail-form-status {
    width: 300px;
    font-size: 0.8rem;
    padding-left: 0.5rem;
    font-weight: 600;
}
