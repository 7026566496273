@import "variables.scss";

.dashboard {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;

    .selected {
        background-color: $theme_emerald_light;
    }

    button {
        margin-top: 1rem;
        border: none;
        border-radius: 5px;
        background: white;
        padding: 0.3rem 1rem;
        color: $theme_gunmetal;
        font-size: 1.2rem;
    }

    input {
        border: none;
        border-radius: 5px;
        padding: 0.3rem;
        margin: 0.3rem;
        color: $theme_gunmetal;
    }
}

.panel {
    padding: 1rem;
    max-width: 300px;
}

.entry {
    margin: 1rem;
    padding: 1rem;
    font-size: 1rem;
    text-align: left;
}




.buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 0.5rem;
    margin: 0 15% 0 15%;

    button {
        font-size: 1.1rem;
    }
}

.entry-actions {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    a {
        text-decoration: none;
        margin: 1rem 0.5rem 0 0;
        border: none;
        border-radius: 5px;
        background: white;
        padding: 0.3rem 1rem;
        color: $theme_gunmetal;
        font-size: 1.1rem; 
    }
}

