@import "variables.scss";

.button {
    background-color: white;
    border-radius: 0.5rem;
    color: $theme_gunmetal;
    border: 3px solid transparent;
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-decoration: none;

    h4 {
        margin: 0;
        padding-left: 1rem;
        padding-right: 1rem;
    }

}

.button:hover {
    background-color: transparent;
    border: 3px solid white;
    color: white;

}