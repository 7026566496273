@import "variables.scss";

.divider {
    width: 60%;
    height: 2px;
    background-color: white;
    border-radius: 0.5rem;
    margin: 1rem 0rem 1rem 0rem;
}

.custom-shape-divider-bottom-1642980006 {
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    background-color: transparent;
    margin: 1rem 0 1rem 0;
}

.flip {
    transform: rotate(0deg);
}

.custom-shape-divider-bottom-1642980006 svg {
    position: relative;
    display: block;
    width: calc(120% + 1.3px);
    height: 50px;
}

.custom-shape-divider-bottom-1642980006 .shape-fill {
    // fill: $theme_emerald_light;
    fill: none;
    stroke: white;
    stroke-width: 10px;
}

@media (min-width: 768px) {
    .custom-shape-divider-bottom-1642980006 svg {
        width: calc(100% + 1.3px);
        height: 120px;
    }

    .custom-shape-divider-bottom-1642980006 .shape-fill {
        stroke-width: 5px;
    }
    .divider {
        height: 3px;
    }
}