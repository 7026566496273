
@import "variables.scss";

.banner {
    // z-index: 10;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0.5rem 2rem 0.5rem 2rem;
    background: transparentize($theme_emerald_light, 0.5);
    color: white;
}