
.hero-images {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    max-width: 700px;
    max-height: 450px;
}

.hero-image {
    margin: 0.5rem;
    object-fit: cover;
    // box-shadow: 0 2px 5px 1px rgb(61, 61, 61);
}

.hero-background {
    max-height: 450px;
    width: 100%;
    overflow: clip;
}

.restaurant-carousel-temp {
    min-width: 450px;
}

.hero-logo {
    position: absolute;
    max-width: 400px;
    width: 55%;
    margin-top: 15%;
    object-fit: contain;
}

.floating { 
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}
 
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 10px); }
    100%   { transform: translate(0, 0px); }   
}

.desktop-only {
    display: none;
}

@media (min-width: 768px) {
    .mobile-only{
        display: none;
    }
    .desktop-only {
        display: flex;
    }
}