@import "variables.scss";

.partners {
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // justify-content: center;
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-template-columns: repeat(3, 1fr);
    max-width: 700px;
    width: 100%;
    
    gap: 1rem;
    padding: 1rem 0 1rem 0;
}

.partner-image {
    max-width: 200px;
    max-height: 100px;
    width: 100%;
    object-fit: contain;
}

.card-container {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
}

.info-card {
    max-width: 300px;
    padding: 20px;
}

.card-1 {
    background-image: url("../images/top.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    margin-top: 60px;
}

.card-2 {
    background-image: url("../images/middle.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    padding-top: 80px;
}

.card-3 {
    background-image: url("../images/bottom.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    padding-top: 80px;
}


@media (min-width: 768px) {

    .title {
        font-size: 3.25rem;
        margin: 0.5rem 0rem 0.5rem 0rem;
    }

    .card-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 30px;
    }
}
