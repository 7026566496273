@import "variables.scss";

.header {
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    padding: 1rem;

    height: 2rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%;

        color: white;
        font-family: "Open Sans", "Segoe UI", "Lato", -apple-system, BlinkMacSystemFont;

        img {
            margin-top: 2px;
            height: 2rem;
        }
        a {
            text-decoration: none;
            color: inherit;
        }
    }
}

/* Desktop*/
@media (min-width: 768px) {
    .header {
        margin: 0 10%
    }
}
