.menu-iframe {
    width: 80%;
    height: 80vh;
}

a {
    text-decoration: underline;
}

@media (min-width: 768px) {
    .menu-iframe {
        width: 50%;
    }

    .not-on-desktop {
        display: none;
    }

}
